import { useState } from "react";
import {
  Outlet,
  Link,
  NavLink,
  useLoaderData,
  Form,
  redirect,
  useNavigation,
  useSubmit,
} from "react-router-dom";

import { Select, Space, Button, Flex, Badge, Switch, Card } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { formatDate } from '../../utils/date';
import { round } from '../../utils/format';

const { Meta } = Card;




function ActivityItem({ activity }) {
 
  // Our component uses the "edit" state
  // variable to switch between editing
  // and viewing the todo item
  const [edit, setEdit] = useState(false);

  return <div className="race-item col-6 col-md-4 col-xs-12 col-sm-6 featured" >
    <div>
    <Card
    style={{ width: "100%" }}
    // cover={<img alt="example" src={`${REACT_APP_BACKEND}${campaign.banner.data[0].attributes.url}`} />}
  >
    <div className="name">{activity.name}</div>
   <div className="race-info">
    <div className="date">Start date: {formatDate(activity.startDate, 'dd/mm/yy, h:MM:ss TT')}</div>
    <div className="race-distances">
        <span className="badge badge-distance badge-type-sport run">
        {activity.type}
        </span>

        <span className="badge badge-distance">Distance: {round(activity.distance/1000)} km</span>
        <span className="badge badge-distance">Pace: {activity.pace} min/km</span>
    </div>
    {/* <div className="name">{activity.attributes.name}</div> */}
    {/* <div className="name">{activity.attributes.name}</div> */}
    {/* <div className="name">{activity.attributes.description}</div> */}
    </div>
    </Card>
  </div></div>
}

export default ActivityItem;