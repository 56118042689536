import { useState } from "react";
import {
  Outlet,
  Link,
  NavLink,
  useLoaderData,
  Form,
  redirect,
  useNavigation,
  useSubmit,
} from "react-router-dom";
import axios from 'axios';

import { Select, Space, Button, Flex, Badge, Switch, Card } from 'antd';
import { ClockCircleOutlined, SwapRightOutlined } from '@ant-design/icons';

import './Campaign.css';
import { formatDate } from '../../utils/date';
import { toSeoUrl } from '../../utils/format';

const { Meta } = Card;
const REACT_APP_BACKEND = process.env.REACT_APP_BACKEND;


function CampaignItem({ campaign, update }) {
  const { attributes } = campaign;
  // <img src={campaign.attributes.banner.data[0].attributes.url} />

  // Our component uses the "edit" state
  // variable to switch between editing
  // and viewing the todo item
  const [edit, setEdit] = useState(false);
  const [newTodo, setNewTodo] = useState("");
  var img = '';
  if(attributes?.banner?.data){
    if(attributes?.banner?.data[0]?.attributes?.formats?.small){
      img = attributes?.banner?.data[0]?.attributes?.formats?.small?.url
    }else if (attributes?.banner?.data[0]?.attributes?.thumbnail?.url){
      img = attributes?.banner?.data[0]?.attributes?.thumbnail?.url
    }
  }
  
  return <Link to={`/campaign/${toSeoUrl(attributes.name)}/${campaign.id}`} className="race-item col-6 col-md-4 col-xs-12 col-sm-6 featured" >
    <Card
    style={{ width: "100%"}}
    cover={<img alt="" 
      style={{ }}
      src={`${REACT_APP_BACKEND}${img}`} />}
    >
    <Meta title={attributes.name} />
    <div className="race-info">
    <div className="date"><ClockCircleOutlined /> Register date: {formatDate(attributes.register_start)} - {formatDate(attributes.register_end)}</div>
    <div className="date"><ClockCircleOutlined /> Race date: {formatDate(attributes.start_date)} - {formatDate(attributes.end_date)}</div>
    <div className="date"><SwapRightOutlined /> Pace: { !attributes?.min_pace && !attributes?.max_pace ? 'Không giới hạn' :  `${attributes?.min_pace} - ${attributes?.max_pace}`}</div>
    <div className="race-distances">
        <Badge
            className="site-badge-count-109"
            count={attributes.sport_type.join(', ')}
            style={{ backgroundColor: '#13D6D2' }}
          />

        {
          attributes.race_distances.map((distance, i) => {
            return <Badge
            className="site-badge-count-109"
            key={`distance_` + i}
            count={distance + ' km'}
            style={{ backgroundColor: '#52c41a' }}
          />
          })
        }
    </div>
    </div>
    </Card>
    </Link>
}

export default CampaignItem;