
import { useState, useEffect } from 'react';
import {
  Outlet,
  Link,
  NavLink,
  useLoaderData,
  Form,
  redirect,
  useNavigation,
  useSubmit,
} from "react-router-dom";
import axios from 'axios';
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import { useJwt } from "react-jwt";

import { Select, Space, Button, Flex, Badge, Switch, Card, Alert,
  Avatar, List, Skeleton
 } from 'antd';
import { ClockCircleOutlined, CloseSquareFilled, SwapRightOutlined } from '@ant-design/icons';


import './Campaign.css';
import { formatDate } from '../../utils/date';
import { round, isNumeric, calculatePace } from '../../utils/format';

const { Meta } = Card;

const REACT_APP_BACKEND = process.env.REACT_APP_BACKEND;
export async function loader({ params }) {
    const campaign = await getCampaign(params.campaignId);
    if (!campaign) {
      throw new Response("", {
        status: 404,
        statusText: "Not Found",
      });
    }
    return { campaign, id: params.campaignId};
}
function getCampaign(id) {
    return fetch(`${REACT_APP_BACKEND}/api/run-campaigns/${id}?populate[0]=banner`)
      .then(res => res.json())
      .then(campaign => {
        return campaign.data.attributes
      })
}
function getUserCampaign(id) {
  return fetch(`${REACT_APP_BACKEND}/api/run-campaigns/${id}?populate[0]=banner`)
    .then(res => res.json())
    .then(campaign => {
      return campaign.data.attributes
    })
}

export async function action({ request, params }) {
    let formData = await request.formData();
}

export default function CampaignDetail() {
    const { campaign, id } = useLoaderData();
    console.log('campaign', campaign)
//   const navigate = useNavigate();
  // Our component uses the "edit" state
  // variable to switch between editing
  // and viewing the todo item
  const [edit, setEdit] = useState(false);
  const [newTodo, setNewTodo] = useState("");
  const [registerCampaignData, setRegisterCampaign] = useState({});
  const [userCampaign, setUserCampaign] = useState([]);
  const [chooseDistance, setDistance] = useState();
  const [leaderBoard, setLeaderBoard] = useState([]);
  const { user, isLoggedIn } = useAuth();
  useEffect(() => {
    // update update the list of todos
    // when the component is rendered for the first time
    getUserCampaign();
    getLeaderBoard(id);
  }, []);
  function registerCampaign(data) {
    if(!isLoggedIn){
      alert("Vui lòng đăng nhập!");
      return;
    }
    const distance = chooseDistance;
    console.log('distance',distance);
    if(!distance){
      alert('Vui lòng chọn cự ly!');
      return;
    }
    console.log('registerCampaign', data)
    return axios.post(`${REACT_APP_BACKEND}/api/strava-provider/register-campaign`,
    {
      campaignId: parseInt(data.campaignId),
      distance: parseInt(distance) ? parseInt(distance) : 0,
    },
    {
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${user?.jwt}`,
      },
    })
    .then(response => response.data)
    .then(campaign => {
      console.log('setRegisterCampaign', campaign)
      if(campaign.status == 'success'){
        //handle success
        alert(campaign.message)
        setUserCampaign([
          {
            campaign:{
              id: data.campaignId
            },
            distance: campaign.data.distance
          }
        ])
      }else if(campaign.message){
        //handle error
        alert(campaign.message)
      }
      setRegisterCampaign(campaign);
    })
  }
  function getUserCampaign() {
    if(!isLoggedIn) return;
    return axios.get(`${REACT_APP_BACKEND}/api/strava-provider/get-user-campaign?populate[0]=campaign`,
    {
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${user?.jwt}`,
      },
    })
    .then(response => response.data)
    .then(campaign => {
      console.log('getUserCampaign', campaign)
      if(campaign && campaign.status == 'success'){
        setUserCampaign(campaign.data);
      }
    })
  }
  function getLeaderBoard(campaignId) {
    return axios.get(`${REACT_APP_BACKEND}/api/strava-provider/campaign/${campaignId}/get-leader-board`,
    {
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${user?.jwt}`,
      },
    })
    .then(response => response.data)
    .then(result => {
      console.log('getLeaderBoard', result)
      if(result && result.status == 'success'){
        setLeaderBoard(result.data);
      }
    })
  }
  function handleChooseDistance(e){
    console.log('handleChooseDistance', e)
    setDistance(e)
  }
  const registeredCampaign = userCampaign.filter(e => e.campaign?.id == id)
  let registeredDistance;
  let isRegistered = false;
  if(registeredCampaign.length > 0){
    registeredDistance = registeredCampaign[0].distance
    isRegistered = true;
  }
  if(registeredDistance == 0) registeredDistance = 'Không giới hạn';
  console.log('isRegistered', isRegistered, userCampaign, id, registeredCampaign)
  console.log('leaderboard', leaderBoard)

  var img = '';
  if(campaign?.banner?.data){
    if(campaign?.banner?.data[0]?.attributes?.url){
      img = campaign?.banner?.data[0]?.attributes?.url
    }else if (campaign?.banner?.data[0]?.attributes?.medium?.url){
      img = campaign?.banner?.data[0]?.attributes?.medium?.url
    }
  }

  return <div className="col-md-12" >
    <Card
    style={{ width: "100%" }}
    cover={<img className="cover-banner" src={`${REACT_APP_BACKEND}${img}`} />}
  >
    <Meta title={campaign.name} description={campaign.description} />
    <div className="race-info">
   <div className="date"><ClockCircleOutlined /> Register date: {formatDate(campaign.register_start)} - {formatDate(campaign.register_end)}</div>
    <div className="date"><ClockCircleOutlined /> Race date: {formatDate(campaign.start_date)} - {formatDate(campaign.end_date)}</div>
    <div className="date"><SwapRightOutlined /> Pace: { !campaign.min_pace && !campaign.max_pace ? 'Không giới hạn' :  `${campaign.min_pace} - ${campaign.max_pace}`}</div>
    
    <div className="race-distances">
        <Badge
            className="site-badge-count-109"
            count={campaign.sport_type.join(', ')}
            style={{ backgroundColor: '#13D6D2' }}
          />

        {
          campaign.race_distances.map((distance, i) => {
            return <Badge
            className="site-badge-count-109"
            key={`distance_` + i}
            count={distance + ' km'}
            style={{ backgroundColor: registeredDistance == distance ? '#EA4710': '#52c41a' }}
          />
          })
        }
    </div>
    
    
    {/* <div className="name">{campaign.name}</div> */}
    {/* <div className="name">{campaign.name}</div> */}
    {/* <div className="name">{campaign.description}</div> */}
    </div>
    <div>
    {
      isRegistered ? <button disabled={true} className='btn btn-secondary'>Đã đăng ký</button>
      :
      <div>
        <Space wrap>
        <Select
          // defaultValue="10"
          style={{ width: 120 }}
          onChange={handleChooseDistance}
          options={campaign.race_distances.map((distance, i) => ({value: distance, label: `${distance} km` }))}
        />
      </Space>
      {/* <button onClick={(props) => registerCampaign({campaignId: id, distance: 10})} className={`btn btn-primary ${chooseDistance? '' : 'disabled'}`} disabled={chooseDistance? false:true}>Đăng ký</button> */}
      <Button onClick={(props) => registerCampaign({campaignId: id, distance: 10})} type="primary" disabled={chooseDistance? false:true}>
        Đăng ký
      </Button>
      </div>
    }</div>
    <>
    <div><span>Bảng xếp hạng</span></div>
    {
      <List
      className="demo-loadmore-list"
      // loading={initLoading}
      itemLayout="horizontal"
      // loadMore={loadMore}
      dataSource={leaderBoard}
      renderItem={(item, index) => (
        <List.Item
          actions={[<a key="list-loadmore-edit">{round(item.total_distance/1000)}{item.distance_register? '/'+ item.distance_register : ''} km</a>, <a key="list-loadmore-more">{calculatePace(item.total_moving_time, item.total_distance)}</a>]}
        >
          <Skeleton avatar title={false} loading={item.loading} active>
            <List.Item.Meta
              avatar={<Avatar src={item.avatar} />}
              title={<a href={`/athlete/${item.strava_id}`} >{item.fullname}</a>}
              // title={item.fullname}
              description={`BIB: ${item.strava_id}`}
            />
          </Skeleton>
        </List.Item>
      )}
    />
    }
    </>
  </Card>
  </div>
}
